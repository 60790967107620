<template>
  <div>
    <!-- 顶部图片 -->
    <div class="header">DOWNLOADS</div>

    <div class="formBox">
      <div class="form">
        <div class="form_main">
          <!-- 第一个选择框的内容 -->
          <div>
            <el-select
              v-model="checkedCategor"
              filterable
              placeholder="Search by category"
              class="select select2"
			  no-data-text="No data"
              @change="checkCategory"
            >
              <el-option
                v-for="item in categorList"
                :key="item.value"
                :label="item.value"
                :value="item"
              >
              </el-option>
            </el-select>
            <img src="@/assets/index/popUp.png" alt="" />
          </div>
          <!-- 第二个选择框的内容 -->
          <div>
            <el-select
              v-model="checkedSubclass"
              filterable
              placeholder="Search by subclass"
              class="select select2"
			  no-data-text="No data"
              @change="checkSubclass"
            >
              <el-option
                v-for="item in subclassList"
                :key="item.value"
                :label="item.value"
                :value="item"
              >
              </el-option>
            </el-select>
            <img src="@/assets/index/popUp.png" alt="" />
          </div>

          <!-- 第三个选择（输入）框的内容 -->

          <div class="select">
            <input
              type="text"
              placeholder="Search by keyword"
              v-model="keyword"
            />
          </div>
        </div>
        <div class="form_submit" @click="submit">SEARCH</div>
      </div>
    </div>

    <!-- search -->
    <!-- 当搜索到数据时的样式 -->
    <div class="search" v-if="searchList.length != 0">
      <div class="searchBox" v-for="(item, index) in searchList" :key="index">
        <div class="searchBox_title">{{ item.documentType }}</div>
        <div
          class="searchBox_name"
          v-for="(item2, index2) in item.seriesList"
          :key="index2"
        >
          <div>{{ item2.seriesName }}</div>
          <div
            class="searchBox_name_list"
            v-for="(item3, index3) in item2.fileList"
            :key="index3"
          >
            <div>
              <!-- <a :href="item3.fileUrl" download target="_blank"> -->
                <img src="@/assets/index/download.png" alt=""  @click="download(item3.fileUrl,item3.fileName)"/>
              <!-- </a> -->    
              {{ item3.fileName }}
            </div>
            <div>
              <img
                @click="downloadOpen(item3.fileUrl)"
                src="@/assets/index/pdf.png"
                alt=""
              /><span>pdf</span>{{ (item3.fileSize/1024).toFixed(2) }}M
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 当未搜索到数据时的样式 -->
    <div class="search2" v-if="searchList.length == 0">
      Please choose one or more filters and press Search button
    </div>
  </div>
</template>

<script>
import api from "@/utils/api";
// import downloadjs from "downloadjs";
export default {
  mounted() {
    console.log(this.$route.query);
    if (this.$route.query.checkedCategor) {
      this.checkedCategor = this.$route.query.checkedCategor;
      this.categorId = this.$route.query.categorId;
    }
    if (this.$route.query.checkedSubclass) {
      this.checkedSubclass = this.$route.query.checkedSubclass;
      this.subclassId = this.$route.query.subclassId;
    }
    //面包屑导航
    this.breadcrumbList = [
      { path: "/", title: "HOME" },
      { path: "", title: "SUPPORT & SERVICE" },
    ];
    this.productOneTypeList();
    let data = {
      keyword: this.keyword,
      oneTypeId: this.categorId,
      twoTypeId: this.subclassId,
    };
    api.supportServiceDownloadSearch(data).then((res) => {
      let data = res.data;
      if (data.code == 200) {
        this.searchList = data.data;
      } else {
        this.$messags.error(res.msg);
      }
    });
  },
  data() {
    return {
      breadcrumbList: [],
      categorList: [],
      checkedCategor: "",

      //Search by subclass
      subclassList: [],
      checkedSubclass: "",

      searchList: [], //请求的数据
      categorId: "", //一级ID
      subclassId: "", //二级ID
      keyword: "", //产品名称
    };
  },
  methods: {
    //Search by category
    checkCategory(item) {
      this.categorId = item.id;
      this.checkedCategor = item.value;
      this.subclassId = "";
      this.checkedSubclass = "";
      api.productTwoTypeList({ oneTypeId: item.id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.subclassList = [];
          data.data.forEach((item) => {
            this.subclassList.push({ value: item.name, id: item.id });
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    checkSubclass(item) {
      this.subclassId = item.id;
      this.checkedSubclass = item.value;
    },

    //支持服务-下载-搜索
    supportServiceDownloadSearch() {
      //判断用户输入的数据
      let data = {
        keyword: this.keyword,
        oneTypeId: this.categorId,
        twoTypeId: this.subclassId,
      };
      api.supportServiceDownloadSearch(data).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.searchList = data.data;
        } else {
          this.$messags.error(res.msg);
        }
      });
    },
    //预览
    downloadOpen(url) {
      //下载接口
      window.open(url);
    },
    //下载
    download(url,name) {
      //下载接口
      let url2 = window.URL.createObjectURL(new Blob([url]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url2;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      console.log(url);
    },
    //搜索（search）
    submit() {
      this.supportServiceDownloadSearch();
    },
    //获取产品一级分类
    productOneTypeList() {
      api.productOneTypeList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.categorList = [];
          data.data.forEach((item) => {
            this.categorList.push({ value: item.name, id: item.id });
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../../assets/index/carousel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  line-height: 186px;
  text-align: center;
}
.formBox {
  background: #f0f2f7;
  padding-bottom: 53px;
}
.form {
  width: 1156px;
  margin: 0 auto;
  padding-top: 30px;
  font-family: Arial;
  font-weight: normal;
  .form_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
    .select {
      width: 263px;
      height: 45px;
      background: #ffffff;
      border: 1px solid #004a9c;
      margin: 0 12.5px;
      position: relative;

      font-size: 16px;
      font-family: Arial;
      font-weight: normal;
      color: #172f66;

      padding: 0 19px 0 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 12px;
        height: 6px;
      }
      > div {
        position: absolute;

        width: 100%;
        left: 0;
        top: 45px;
        ul {
          list-style: none;
          padding: 0;
          background: #ffffff;
          li {
            padding: 0px 10px;
            line-height: 45px;
            height: 45px;
            outline: 1px solid #004a9c;
          }
        }
      }
      > input {
        height: 43px;
        border: 0;
        width: 100%;
        padding: 0;
        font-size: 16px;
      }
      > input:focus {
        border: 0;
        outline: 0px;
      }
    }
    .select2 {
      width: 360px;
      padding: 0;
    }
    > div {
      position: relative;
      img {
        position: absolute;
        z-index: 11;
        right: 26px;
        top: 20px;
        width: 12px;
        height: 6px;
      }
    }
  }
  textarea {
    width: 1127px;
    height: 125px;
    background: #ffffff;
    border: 1px solid #004a9c;
    padding: 14px;
  }
  .form_submit {
    width: 263px;
    height: 45px;
    background: #004a9c;
    font-size: 18px;
    text-align: center;
    line-height: 45px;
    color: #ffffff;
    cursor: pointer;
    margin: 43px auto 0;
  }
}
.search {
  width: 1156px;
  margin: 0 auto;
  .searchBox {
    .searchBox_title {
      font-size: 22px;
      font-family: Arial;
      font-weight: bold;
      color: #004a9c;
      padding: 45px 0 28px;
    }
    .searchBox_name {
      > div:nth-of-type(1) {
        font-size: 16px;
        color: #5a5b5e;
        margin-bottom: 15px;
      }
      .searchBox_name_list {
        display: flex;
        justify-content: space-between;
        height: 45px;
        cursor: pointer;
        > div {
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #5a5b5e;
        }
        > div:nth-of-type(1) {
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            width: 21px;
            height: 20px;
            padding: 0 60px 0 42px;
          }
        }

        > div:nth-of-type(2) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 37px;
          img {
            width: 20px;
            height: 23px;
          }
          span {
            padding-left: 7px;
            padding-right: 100px;
          }
        }
      }
      .searchBox_name_list:nth-last-of-type(1) {
        margin-bottom: 20px;
      }
      .searchBox_name_list:nth-of-type(2n) {
        background: #f1f2f7;
      }
    }
  }
}
.search2 {
  height: 253px;
  text-align: center;
  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #5a5b5e;
  padding: 85px;
}
</style>


<style scoped>
div /deep/ .el-select {
  height: 45px;
}
div /deep/ .el-input__suffix-inner {
  display: none;
}
div /deep/ .el-input__inner {
  height: 45px;
  border: 0;
}
</style>