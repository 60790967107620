import { render, staticRenderFns } from "./downloads.vue?vue&type=template&id=3e2d67b7&scoped=true&"
import script from "./downloads.vue?vue&type=script&lang=js&"
export * from "./downloads.vue?vue&type=script&lang=js&"
import style0 from "./downloads.vue?vue&type=style&index=0&id=3e2d67b7&lang=scss&scoped=true&"
import style1 from "./downloads.vue?vue&type=style&index=1&id=3e2d67b7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e2d67b7",
  null
  
)

export default component.exports